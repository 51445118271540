import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Div100vh from "react-div-100vh"
import Img from "gatsby-image"

import { Button } from "../elements/Button"
import { IconPlus } from "../icons/IconPlus"
import { IconCaret } from "../icons/IconCaret"

import { PhotosType } from "../helpers/types"
import {
  BUTTON__BACK,
  LOCATION_PHOTO,
  NEXT,
  PREVIOUS,
} from "../providers/texts"

import styles from "./DetailImage.module.scss"

interface Props {
  gallery: PhotosType
  screenType: string
  goToDetailDescription: () => void
}

interface QueryResult {
  node: {
    relativePath: string[]
    childImageSharp: {
      fluid: {
        aspectRatio: number
        base64: string
        sizes: string
        src: string
        srcSet: string
      }
    }
  }
}

const DetailImage: React.FC<Props> = ({
  gallery,
  screenType,
  goToDetailDescription,
}: Props) => {
  const [displayedImage, setDisplayedImage] = useState(1)

  const data = useStaticQuery(graphql`
    query {
      smallImages: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      largeImages: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const getImageList = () => {
    const imageSizeDefault = data.smallImages
    const imageInfoDefault = `${gallery.id}/small/${displayedImage}.jpeg`

    const imageSize =
      screenType === "small" ? data.smallImages : data.largeImages
    const imageInfo = `${gallery.id}/${screenType}/${displayedImage}.jpeg`

    let imageDataRef = imageSize.edges.find((result: QueryResult) => {
      return result.node.relativePath.includes(imageInfo)
    })
    if (!imageDataRef) {
      imageDataRef = imageSizeDefault.edges.find((result: QueryResult) => {
        return result.node.relativePath.includes(imageInfoDefault)
      })
    }

    return imageDataRef && imageDataRef.node
  }

  const imageData = getImageList()

  const handleSetDisplayedImage = (
    step: number,
    totalImageNo: number,
    currentImageNo: number
  ) => {
    let newImage = currentImageNo + step

    if (!newImage) {
      newImage = totalImageNo
    } else if (totalImageNo < newImage) {
      newImage = 1
    }

    setDisplayedImage(newImage)
  }

  return (
    <Div100vh>
      <div className={styles.container}>
        <Img
          fluid={imageData.childImageSharp.fluid}
          className={styles.image}
          imgStyle={
            screenType === "large"
              ? {
                  objectFit: "contain",
                }
              : {}
          }
          alt={LOCATION_PHOTO}
        />
        <Button
          ariaLabel={BUTTON__BACK}
          className={styles.backButton}
          onClick={goToDetailDescription}
        >
          <IconPlus />
        </Button>
        <Button
          ariaLabel={NEXT}
          className={`${styles.leftButton} ${
            (gallery.amount > 1 && displayedImage > 1) || styles.hidden
          }`}
          onClick={() =>
            handleSetDisplayedImage(-1, gallery.amount, displayedImage)
          }
        >
          <IconCaret />
        </Button>
        <Button
          ariaLabel={PREVIOUS}
          className={`${styles.rightButton} ${
            (gallery.amount > 1 && gallery.amount > displayedImage) ||
            styles.hidden
          }`}
          onClick={() =>
            handleSetDisplayedImage(1, gallery.amount, displayedImage)
          }
        >
          <IconCaret />
        </Button>
      </div>
    </Div100vh>
  )
}

export { DetailImage }
