import React from "react"

import { IconLogo } from "../icons/IconLogo"
import { Location } from "../helpers/types"

import styles from "./Marker.module.scss"

interface Props {
  item: Location
  onFocus: boolean
  onChangeId: (id: number) => void
}

const Marker: React.FC<Props> = ({ item, onChangeId, onFocus }: Props) => {
  return (
    <button
      aria-label={item.title}
      onClick={() => onChangeId(item.id)}
      className={`${styles.wrapper} ${onFocus && styles.wrapper__onFocus} ${
        item && item.type && item.type === "BATHROOM"
          ? styles.bathroom
          : styles.babyroom
      } `}
    >
      <div
        className={`${styles.background} ${
          onFocus && styles.background__onFocus
        }`}
      />
      <div
        className={`${styles.borderOuter} ${
          onFocus && styles.borderOuter__onFocus
        } `}
      >
        <IconLogo />
      </div>
      <div className={`${styles.border} ${onFocus && styles.border__onFocus}`}>
        <IconLogo />
      </div>
      <div
        className={`${styles.marker} ${
          item && item.type && item.type === "BATHROOM"
            ? styles.bathroom
            : styles.babyroom
        } ${onFocus ? styles.marker__onFocus : ""}`}
      >
        <IconLogo />
      </div>
    </button>
  )
}

export { Marker }
