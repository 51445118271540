import React, { useState } from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

import { IconCookie } from "../icons/IconCookie"
import { IconPlus } from "../icons/IconPlus"

import {
  COOKIE_CONSENT,
  COOKIE_CONSENT__UNDERSTAND,
  COOKIE_CONSENT__MORE_INFO,
} from "../providers/texts"

import styles from "./CookiesConsentBar.module.scss"

const CookiesConsentBar: React.FC = () => {
  const [bannerIsVisible, setBannerIsVisible] = useState(true)
  const handleSetBannerIsVisible = (newState: boolean) =>
    setBannerIsVisible(newState)

  const banner = (
    <CookieConsent
      buttonText={COOKIE_CONSENT__UNDERSTAND}
      cookieName="gatsby-gdpr-google-analytics"
      containerClasses={styles.container}
      contentClasses={styles.content}
      buttonClasses={`${styles.buttons} ${styles.button__agree}`}
      buttonWrapperClasses={styles.wrapper}
    >
      {COOKIE_CONSENT} (
      <Link to="/cookies" className={styles.link}>
        {COOKIE_CONSENT__MORE_INFO}
      </Link>
      ).
      <button
        className={styles.button__hide}
        onClick={() => handleSetBannerIsVisible(false)}
      >
        <IconPlus />
      </button>
    </CookieConsent>
  )

  const notification = (
    <button
      className={styles.notification}
      onClick={() => handleSetBannerIsVisible(true)}
    >
      <IconCookie />
    </button>
  )

  return bannerIsVisible ? banner : notification
}

export { CookiesConsentBar }
