import React, { useState } from "react"

import { Header } from "./Header"
import { DetailImage } from "./DetailImage"
import { DetailThumbnail } from "./DetailThumbnail"
import { Button } from "../elements/Button"

import { IconPlus } from "../icons/IconPlus"
import { IconCheckmark } from "../icons/IconCheckmark"

import { defaultContentView, DETAIL_VIEW } from "../helpers/enums"
import { Location, VisibleContent, Amenities } from "../helpers/types"

import * as texts from "../providers/texts"

import styles from "./Detail.module.scss"

interface Props {
  activeLocation: Location
  goBack: (updatedVisibleContent: VisibleContent) => void
}

const Detail: React.FC<Props> = ({ activeLocation, goBack }: Props) => {
  const [detailView, setDetailView] = useState(DETAIL_VIEW.DESCRIPTION)

  const handleSetDetailView = (updatedDetailView: string) => {
    setDetailView(updatedDetailView)
  }

  const determineAmenityAvailability = (amenityType: keyof Amenities) => {
    const hasAmenity =
      activeLocation &&
      activeLocation.amenities &&
      activeLocation.amenities[amenityType]

    if (hasAmenity) {
      return <IconCheckmark />
    } else {
      return (
        <div className={styles.amenities__hasNotAmenity}>
          <IconPlus />
        </div>
      )
    }
  }

  const determineIfActiveLocationHasGallery = (activeLocation: Location) =>
    activeLocation && activeLocation.photos && !!activeLocation.photos.amount

  const generateLargeScreenGallery = (
    activeLocation: Location,
    displayGallery: boolean
  ) => {
    const activeLocationHasGallery = determineIfActiveLocationHasGallery(
      activeLocation
    )
    if (activeLocationHasGallery && displayGallery) {
      return (
        <div className={styles.galleryLargeScreens}>
          <DetailImage
            gallery={activeLocation.photos}
            screenType="large"
            goToDetailDescription={() =>
              handleSetDetailView(DETAIL_VIEW.DESCRIPTION)
            }
          />
        </div>
      )
    }
  }

  const generateGalleryThumbnail = (activeLocation: Location) => {
    const activeLocationHasGallery = determineIfActiveLocationHasGallery(
      activeLocation
    )

    if (activeLocationHasGallery) {
      return (
        <>
          <div className={styles.thumbnail__smallScreens}>
            <DetailThumbnail
              gallery={activeLocation.photos}
              openGallery={() =>
                handleSetDetailView(DETAIL_VIEW.GALLERY_SMALLSCREENS)
              }
            />
          </div>
          <div className={styles.thumbnail__largeScreens}>
            <DetailThumbnail
              gallery={activeLocation.photos}
              openGallery={() =>
                handleSetDetailView(DETAIL_VIEW.GALLERY_LARGESCREENS)
              }
            />
          </div>
        </>
      )
    }
  }

  const generateDetailDescription = () => {
    return (
      <div className={styles.container}>
        {generateLargeScreenGallery(
          activeLocation,
          detailView === DETAIL_VIEW.GALLERY_LARGESCREENS
        )}
        <div className={styles.headerSmallScreens}>
          <Header
            locationType={activeLocation.type}
            subtitle={activeLocation.title}
            resetState={() => null}
          />
        </div>
        <div className={styles.headerLargeScreens}>
          <div className={styles.headerLargeScreen__title}>
            {activeLocation && activeLocation.title}
          </div>
          <div>
            {activeLocation && activeLocation.type
              ? activeLocation.type === "BATHROOM"
                ? texts.LOCATION__TYPE__BATHROOM
                : texts.LOCATION__TYPE__NURSINGROOM
              : ""}
          </div>
        </div>

        <div className={styles.address}>
          {activeLocation &&
            activeLocation.address.map((addressLine, index) => (
              <span key={index}>
                {addressLine}
                <br />
              </span>
            ))}
        </div>

        <div className={styles.description}>
          {activeLocation && activeLocation.description}
        </div>

        <div className={styles.amenities}>
          <div className={styles.amenities__amenity}>
            {determineAmenityAvailability("hasNappyChangingUtilities")}
            {texts.LOCATION__AMENITY__HASNAPPYCHANGINGUTILITIES}
          </div>
          <div className={styles.amenities__amenity}>
            {determineAmenityAvailability("hasLock")}
            {texts.LOCATION__AMENITY__HASLOCK}
          </div>
          <div className={styles.amenities__amenity}>
            {determineAmenityAvailability("hasRunningWater")}
            {texts.LOCATION__AMENITY__HASRUNNINGWATER}
          </div>
          <div className={styles.amenities__amenity}>
            {determineAmenityAvailability("hasMicrowave")}
            {texts.LOCATION__AMENITY__HASMICROWAVE}
          </div>
        </div>

        {generateGalleryThumbnail(activeLocation)}

        <div className={styles.lastUpdated}>
          {texts.LOCATION__LAST_VISITED}{" "}
          {activeLocation && activeLocation.lastUpdated}
        </div>

        <div className={styles.backButton}>
          <Button
            ariaLabel={texts.BUTTON__BACK_TO_MAP}
            className={styles.backButton__icon}
            onClick={() => goBack(defaultContentView)}
          >
            <IconPlus />
          </Button>
        </div>
      </div>
    )
  }

  switch (detailView) {
    case DETAIL_VIEW.GALLERY_SMALLSCREENS:
      return (
        <DetailImage
          gallery={activeLocation.photos}
          screenType="small"
          goToDetailDescription={() =>
            handleSetDetailView(DETAIL_VIEW.DESCRIPTION)
          }
        />
      )

    case DETAIL_VIEW.GALLERY_LARGESCREENS:
    case DETAIL_VIEW.DESCRIPTION:
    default:
      return generateDetailDescription()
  }
}

export { Detail }
