import React from "react"

const IconInfo: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 256.000000 256.000000"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)">
        <path
          d="M1115 2429 c-166 -24 -396 -119 -525 -218 -84 -63 -214 -199 -269
-279 -231 -340 -264 -778 -86 -1149 58 -123 112 -200 209 -301 107 -112 189
-172 321 -237 332 -163 685 -167 1013 -10 131 63 226 132 329 238 105 108 158
184 218 310 157 326 153 684 -10 1012 -63 128 -115 200 -222 308 -250 252
-625 377 -978 326z m311 -120 c328 -45 628 -260 778 -556 289 -569 9 -1252
-596 -1459 -483 -164 -1026 61 -1256 521 -148 297 -149 631 -1 929 53 106 112
187 200 273 90 88 165 142 267 193 189 94 399 128 608 99z"
        />
        <path
          d="M1200 1938 c-132 -67 -132 -249 0 -315 24 -13 60 -23 80 -23 20 0 56
10 80 23 132 66 132 248 0 315 -24 12 -60 22 -80 22 -20 0 -56 -10 -80 -22z"
        />
        <path
          d="M1205 1361 c-48 -22 -69 -44 -90 -94 -12 -30 -15 -86 -15 -310 0
-263 1 -274 23 -317 66 -132 248 -132 315 0 21 43 22 54 22 320 0 245 -2 279
-19 315 -22 48 -44 69 -94 90 -48 20 -93 19 -142 -4z"
        />
      </g>
    </svg>
  )
}

export { IconInfo }
