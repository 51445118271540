import React from "react"
import { Point } from "pigeon-maps"
import { toast } from "react-toastify"

import { Toast } from "../components/Toast"

import {
  defaultLatN,
  defaultLatS,
  defaultLonE,
  defaultLonW,
} from "../providers/maptiles"
import { WITHIN_PRAGUE_BOUNDS } from "../providers/texts"
import { setCookie } from "./cookieHandlers"

const handlePragueCentricWarning = (): void => {
  const pragueBoundsCookie = document.cookie.includes("pragueBoundsCheck")
  if (!pragueBoundsCookie) {
    toast(<Toast message={WITHIN_PRAGUE_BOUNDS} />)
    setCookie("pragueBoundsCheck", "true", 1)
  }
}

const userIsInPrague = (currentPosition: Point): boolean =>
  currentPosition[0] > defaultLatS &&
  currentPosition[0] < defaultLatN &&
  currentPosition[1] > defaultLonW &&
  currentPosition[1] < defaultLonE

export { handlePragueCentricWarning, userIsInPrague }
