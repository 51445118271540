import React from "react"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { App } from "../components/App"
import { SEO } from "../components/Seo"

export default function NursingRoomsApp(): React.ReactElement {
  return (
    <>
      <SEO />
      <ToastContainer newestOnTop position="bottom-right" />
      <App />
    </>
  )
}
