import React, { ChangeEvent } from "react"

import { Spinner } from "../elements/Spinner"
import { Checkbox } from "../elements/Checkbox"
import { ButtonLabel } from "../elements/ButtonLabel"
import { IconNavigate } from "../icons/IconNavigate"

import * as texts from "../providers/texts"
import { FiltersType } from "../helpers/types"
import { FILTERS_ENUM } from "../helpers/enums"

import styles from "./Filters.module.scss"

interface Props {
  activeFilters: FiltersType
  loading: { isLoading: boolean; id: string }
  setActiveFilters: (filters: FiltersType) => void
  setClosestLocation: () => void
}

const Filters: React.FC<Props> = ({
  loading,
  activeFilters,
  setActiveFilters,
  setClosestLocation,
}: Props) => {
  const handleSetActiveFilters = (
    param: keyof FiltersType,
    event?: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedState = JSON.parse(JSON.stringify(activeFilters))
    updatedState[param] = event ? event.target.checked : !updatedState[param]
    setActiveFilters(updatedState)
  }

  const generateOptions = (filters: typeof FILTERS_ENUM) => {
    const mappableObject = Object.keys(filters)

    return mappableObject.map(key => {
      let label: string
      let className = styles.option

      if (key === "isPublicNursingRoom") {
        label = "LOCATION__TYPE__NURSINGROOM_FILTERLABEL"
      } else if (key === "isPublicBathroom") {
        label = "LOCATION__TYPE__BATHROOM_FILTERLABEL"
        className = `${styles.option} ${styles.divider}`
      } else {
        label = "LOCATION__AMENITY__" + key.toUpperCase()
      }

      const castKey = key as keyof FiltersType
      const castLabel = label as keyof typeof texts

      return (
        <div key={key} className={className}>
          <Checkbox
            id={key}
            checked={activeFilters[castKey]}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleSetActiveFilters(castKey, event)
            }
          />
          <button
            aria-label={castLabel}
            className={styles.option__button}
            onClick={() => handleSetActiveFilters(castKey)}
          >
            {texts[castLabel]}
          </button>
        </div>
      )
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.filters}>{generateOptions(FILTERS_ENUM)}</div>
      <div className={styles.button}>
        <ButtonLabel
          ariaLabel={texts.BUTTON__CLOSEST}
          onClick={setClosestLocation}
          className={styles.button__lightHover}
        >
          <>
            <IconNavigate />
            <div className={styles.button__label}>
              {loading.isLoading && loading.id === "closestLocationButton" ? (
                <div className={styles.spinner}>
                  <Spinner />
                </div>
              ) : (
                texts.BUTTON__CLOSEST
              )}
            </div>
          </>
        </ButtonLabel>
      </div>
    </div>
  )
}

export { Filters }
