import React, { ReactElement } from "react"
import { BUTTON } from "../providers/texts"
import styles from "./Button.module.scss"

interface Props {
  onClick: () => void
  className?: string
  children: ReactElement
  ariaLabel?: string
}

const Button: React.FC<Props> = ({
  onClick,
  className,
  children,
  ariaLabel = BUTTON,
}: Props) => {
  return (
    <button
      aria-label={ariaLabel}
      className={`${styles.button} ${className || ""}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export { Button }
