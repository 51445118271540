import React from "react"
import { ERROR } from "../providers/texts"
import "./Toast.scss"

interface Props {
  message: string
  error?: Error
  type?: string
}

const Toast: React.FC<Props> = ({ message, error, type }: Props) => {
  const errMessage = error ? ERROR + error.message : ""
  const typeSpecificMessage = type ? type : ""

  return (
    <div>
      {message} {errMessage} {typeSpecificMessage}
    </div>
  )
}

export { Toast }
