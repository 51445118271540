import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Button } from "../elements/Button"
import { IconCaret } from "../icons/IconCaret"

import { PhotosType } from "../helpers/types"
import { LOCATION_PHOTO, DISPLAY_DETAIL_GALLERY } from "../providers/texts"

import styles from "./DetailThumbnail.module.scss"

interface Props {
  gallery: PhotosType
  openGallery: () => void
}

interface QueryResult {
  node: {
    relativePath: string[]
    childImageSharp: {
      fluid: {
        aspectRatio: number
        base64: string
        sizes: string
        src: string
        srcSet: string
      }
    }
  }
}

const DetailThumbnail: React.FC<Props> = ({ gallery, openGallery }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      thumbnail: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const getThumbnail = () => {
    let imageDataRef = data.thumbnail.edges.find((result: QueryResult) => {
      return result.node.relativePath.includes(`${gallery.id}/large/1.jpeg`)
    })
    if (!imageDataRef) {
      imageDataRef = data.thumbnail.edges.find((result: QueryResult) => {
        return result.node.relativePath.includes(`${gallery.id}/small/1.jpeg`)
      })
    }
    return imageDataRef && imageDataRef.node
  }

  const imageData = getThumbnail()

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        ariaLabel={DISPLAY_DETAIL_GALLERY}
        onClick={openGallery}
      >
        <>
          <Img
            fluid={imageData.childImageSharp.fluid}
            className={styles.image}
            alt={LOCATION_PHOTO}
          />
          <div className={styles.icon}>
            <IconCaret />
          </div>
        </>
      </Button>
    </div>
  )
}

export { DetailThumbnail }
