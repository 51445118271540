import React from "react"
import styles from "./Spinner.module.scss"

// Kudos to SpinKit: https://tobiasahlin.com/spinkit/

interface Props {
  color?: string
  size?: string
}

const Spinner: React.FC<Props> = ({
  color = "DEFAULT",
  size = "SMALL",
}: Props) => {
  const getColor = () =>
    color === "PRIMARY"
      ? styles.backgroundColor__primary
      : color === "WHITE"
      ? styles.backgroundColor__white
      : styles.backgroundColor__default

  const getSize = () =>
    size === "LARGE" ? styles.skCircle__large : styles.skCircle__small

  return (
    <div className={`${styles.skCircle} ${getSize()}`}>
      <div
        className={`${styles.skCircle1} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle2} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle3} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle4} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle5} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle6} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle7} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle8} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle9} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle10} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle11} ${styles.skChild} ${getColor()}`}
      ></div>
      <div
        className={`${styles.skCircle12} ${styles.skChild} ${getColor()}`}
      ></div>
    </div>
  )
}

export { Spinner }
