const CONTENT_VIEW = {
  MAP: "map",
  DETAIL: "detail",
}

const MAPTILER_VIEW = {
  MAPYCZ: "mapycz",
  MAPTILER: "maptiler",
}

const DETAIL_VIEW = {
  DESCRIPTION: "description",
  GALLERY_SMALLSCREENS: "gallery_smallscreens",
  GALLERY_LARGESCREENS: "gallery_largescreens",
}

const FILTERS_ENUM = {
  isPublicNursingRoom: "isPublicNursingRoom",
  isPublicBathroom: "isPublicBathroom",
  hasNappyChangingUtilities: "hasNappyChangingUtilities",
  hasLock: "hasLock",
  hasRunningWater: "hasRunningWater",
  hasMicrowave: "hasMicrowave",
}

const defaultContentView = {
  type: CONTENT_VIEW.MAP,
  detail: null,
}

export {
  CONTENT_VIEW,
  defaultContentView,
  DETAIL_VIEW,
  MAPTILER_VIEW,
  FILTERS_ENUM,
}
