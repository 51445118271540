import React from "react"
import Map, { Point } from "pigeon-maps"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Overlay from "pigeon-overlay"

import { Marker } from "./Marker"
import { MapAttribution } from "./MapAttribution"
import { IconMyLocation } from "../icons/IconMyLocation"

import { mapyCz, mapTiler } from "../providers/maptiles"
import { Location, VisibleContent } from "../helpers/types"
import { CONTENT_VIEW, MAPTILER_VIEW } from "../helpers/enums"
import { locationSelector } from "../helpers/locationSelectors"

import styles from "./Map.module.scss"

interface Props {
  locationsList: Location[]
  currentPosition: Point | null
  mapCenter: Point
  mapZoom: number
  onChangeId: ({ type, detail }: VisibleContent) => void
  setMapCenter: (center: Point) => void
  setMapZoom: (zoom: number) => void
  centerMapAction: boolean
  closestLocation: Location | null
  mapTileProvider: string | undefined
}

const CustomMap: React.FC<Props> = ({
  locationsList,
  currentPosition,
  mapCenter,
  mapZoom,
  onChangeId,
  setMapCenter,
  setMapZoom,
  centerMapAction,
  closestLocation,
  mapTileProvider,
}: Props) => {
  const getTileProvider = (provider: string | undefined) =>
    provider === MAPTILER_VIEW.MAPYCZ
      ? mapyCz
      : provider === MAPTILER_VIEW.MAPTILER
      ? mapTiler
      : undefined

  const handleBoundsChange = (center: Point, zoom: number) => {
    setMapCenter(center)
    setMapZoom(zoom)
  }

  const handleDetailChange = (detailId: number) => {
    const activeLocation = locationSelector(detailId)
    if (activeLocation) {
      onChangeId({
        type: CONTENT_VIEW.DETAIL,
        detail: activeLocation,
      })
    }
  }

  const generateMarkers = (
    locationsList: Location[],
    closestLocation: Location | null
  ) => {
    return locationsList.map(item => (
      <Overlay
        anchor={item.coordinates}
        offset={[35, 70]}
        key={item.id}
        className={item.type === "BABYROOM" ? styles.babyroomIcon : styles.bathroomIcon}
      >
        <Marker
          item={item}
          onChangeId={detailId => handleDetailChange(detailId)}
          onFocus={
            closestLocation !== null &&
            closestLocation !== undefined &&
            closestLocation.id === item.id
          }
        />
      </Overlay>
    ))
  }

  const generateCurrentPosition = (
    currentPosition: Point | null,
    centerMapAction: boolean
  ) => {
    return !currentPosition ? null : (
      <Overlay anchor={currentPosition} offset={[25, 25]}>
        <div
          className={`${styles.currentLocationIcon} ${
            centerMapAction && styles.currentLocationIcon__onFocus
          }`}
        >
          <IconMyLocation />
        </div>
      </Overlay>
    )
  }

  return (
    <Map
      provider={getTileProvider(mapTileProvider)}
      center={mapCenter}
      zoom={mapZoom}
      onBoundsChanged={({ center, zoom }) => handleBoundsChange(center, zoom)}
      attribution={<MapAttribution mapProvider={mapTileProvider || ""} />}
    >
      {generateMarkers(locationsList, closestLocation)}
      {generateCurrentPosition(currentPosition, centerMapAction)}
    </Map>
  )
}

export { CustomMap }
