import React from "react"

const IconMyLocation: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="50" cy="50" r="40" />
    </svg>
  )
}

export { IconMyLocation }
