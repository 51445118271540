import { Point } from "pigeon-maps"
import locations from "../providers/locations.json"
import { FiltersType, Location, Amenities } from "./types"

const defaultActiveFilters: FiltersType = {
  isPublicNursingRoom: true,
  isPublicBathroom: true,
  hasLock: false,
  hasNappyChangingUtilities: true,
  hasRunningWater: false,
  hasMicrowave: false,
}

function typeLocations() {
  const typedLocations: Location[] = locations.map(location => {
    const typedCoordinates: Point =
      location.coordinates.length === 2
        ? [location.coordinates[0], location.coordinates[1]]
        : [0, 0]

    return {
      ...location,
      coordinates: typedCoordinates,
    }
  })

  return typedLocations
}

function locationSelector(id: number): Location | undefined {
  const typedLocations: Location[] = typeLocations()
  const foundLocation = typedLocations.find(
    location => location && location.id === id
  )
  return foundLocation
}

function checkIfOnlyLocationTypeFilterIsSelected(selectedFilters: FiltersType) {
  return Object.entries(selectedFilters).every(entry =>
    entry[0] !== "isPublicNursingRoom" && entry[0] !== "isPublicBathroom"
      ? !entry[1]
      : true
  )
}

function filterLocationsByType(
  locationsList: Location[],
  selectedFilters: FiltersType
) {
  return locationsList.filter(location => {
    const nursingRooms = selectedFilters.isPublicNursingRoom
    const bathRooms = selectedFilters.isPublicBathroom

    if (nursingRooms && !bathRooms) {
      return location.type === "BABYROOM"
    } else if (!nursingRooms && bathRooms) {
      return location.type === "BATHROOM"
    } else if (!nursingRooms && !bathRooms) {
      return false
    } else {
      return true
    }
  })
}

function filterLocationsByAmenities(
  locationsList: Location[],
  selectedFilters: FiltersType
) {
  return locationsList.filter(location => {
    const filters: Amenities = {
      hasLock: selectedFilters.hasLock,
      hasNappyChangingUtilities: selectedFilters.hasNappyChangingUtilities,
      hasRunningWater: selectedFilters.hasRunningWater,
      hasMicrowave: selectedFilters.hasMicrowave,
    }

    const filtersNo = Object.entries(filters).filter(key => key[1]).length

    const amenitiesCorrespondingToSelectedFiltersNo = Object.entries(
      location.amenities
    ).filter(amenity => {
      const relevantFilter = Object.entries(filters).find(
        filter => filter[0] === amenity[0]
      )
      if (relevantFilter) {
        return relevantFilter[1] && amenity[1]
      }
    }).length

    return filtersNo === amenitiesCorrespondingToSelectedFiltersNo
  })
}

function locationFilter(activeFilters: FiltersType): Location[] {
  const onlyTypeFiltered = checkIfOnlyLocationTypeFilterIsSelected(
    activeFilters
  )

  const typedLocations: Location[] = typeLocations()

  const locationsOfSelectedType = filterLocationsByType(
    typedLocations,
    activeFilters
  )

  const locationsOfSelectedTypeWithSelectedAmenities = filterLocationsByAmenities(
    locationsOfSelectedType,
    activeFilters
  )

  return onlyTypeFiltered
    ? locationsOfSelectedType
    : locationsOfSelectedTypeWithSelectedAmenities
}

function degTorad(deg: number) {
  return deg * (Math.PI / 180)
}

function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  // From SO – Haversine formula
  const earthRadiusInKm = 6371
  const distanceLat = degTorad(lat2 - lat1)
  const distanceLon = degTorad(lon2 - lon1)
  const a =
    Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
    Math.cos(degTorad(lat1)) *
      Math.cos(degTorad(lat2)) *
      Math.sin(distanceLon / 2) *
      Math.sin(distanceLon / 2)
  const b = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distanceInKm = earthRadiusInKm * b
  return distanceInKm
}

function getAllLocationsWithinRadius(
  activeLocations: Location[],
  currentLocation: number[],
  radius = 1
): Location[] {
  return activeLocations.filter(location => {
    const locationDistance = getDistanceFromLatLonInKm(
      location.coordinates[0],
      location.coordinates[1],
      currentLocation[0],
      currentLocation[1]
    )
    return locationDistance < radius
  })
}

function getClosestLocation(
  activeLocations: Location[],
  currentLocation: number[]
): Location | null {
  if (!activeLocations.length) {
    return null
  }

  return activeLocations.reduce((acc, curr) => {
    const distanceBetweenAccAndCurrentLocation = getDistanceFromLatLonInKm(
      acc.coordinates[0],
      acc.coordinates[1],
      currentLocation[0],
      currentLocation[1]
    )
    const distanceBetweenCurrAndCurrentLocation = getDistanceFromLatLonInKm(
      curr.coordinates[0],
      curr.coordinates[1],
      currentLocation[0],
      currentLocation[1]
    )
    return distanceBetweenCurrAndCurrentLocation <
      distanceBetweenAccAndCurrentLocation
      ? curr
      : acc
  })
}

export {
  defaultActiveFilters,
  locationSelector,
  locationFilter,
  getClosestLocation,
  getAllLocationsWithinRadius,
  getDistanceFromLatLonInKm,
}
