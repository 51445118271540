import axios from "axios"

const handleError = (
  error: Error | GeolocationPositionError,
  location: string
): void => {
  if (process.env.NODE_ENV !== 'production') {
    if (axios.isCancel(error)) {
      console.log("cancelled error")
    } else {
      console.log(location, error)
    }
  }
}

export { handleError }
