import React from "react"
import { Point } from "pigeon-maps"
import { navigate } from "gatsby"

import { Detail } from "./Detail"
import { CustomMap as Map } from "./Map"
import { Filters } from "./Filters"

import { Button } from "../elements/Button"
import { Spinner } from "../elements/Spinner"

import { IconPlus } from "../icons/IconPlus"
import { IconMinus } from "../icons/IconMinus"
import { IconInfo } from "../icons/IconInfo"
import { IconMyLocation } from "../icons/IconMyLocation"

import { CONTENT_VIEW } from "../helpers/enums"
import { VisibleContent, FiltersType, Location } from "../helpers/types"

import {
  ZOOM_IN,
  ZOOM_OUT,
  DISPLAY_ABOUT_PAGE,
  CENTER_MAP,
} from "../providers/texts"

import styles from "./Content.module.scss"

interface Props {
  content: VisibleContent
  filterPanelIsOpen: boolean
  activeFilters: FiltersType
  activeLocations: Location[]
  mapTileProvider: string | undefined
  mapCenter: Point
  currentPosition: Point | null
  mapZoom: number
  centerMapAction: boolean
  closestLocation: Location | null
  loading: { isLoading: boolean; id: string }
  setContent: (updatedContent: VisibleContent) => void
  setFilterPanelIsOpen: () => void
  setActiveFilters: (activeFilters: FiltersType) => void
  setMapCenter: (mapCenter: Point | null) => void
  setMapZoom: (zoom: number) => void
  setClosestLocation: () => void
  setMapCenterOnCurrentLocation: (currentPosition: Point | null) => void
}

const Content: React.FC<Props> = ({
  content,
  filterPanelIsOpen,
  activeFilters,
  activeLocations,
  mapTileProvider,
  mapCenter,
  currentPosition,
  mapZoom,
  centerMapAction,
  closestLocation,
  loading,
  setContent,
  setFilterPanelIsOpen,
  setActiveFilters,
  setMapCenter,
  setMapZoom,
  setClosestLocation,
  setMapCenterOnCurrentLocation,
}: Props) => {
  const generateLargeScreenDetail = (detail: Location | null) => {
    if (detail) {
      return (
        <div
          className={`${styles.detailLargeScreen} ${
            content.type === CONTENT_VIEW.DETAIL ? "" : styles.hidden
          }`}
        >
          <Detail activeLocation={detail} goBack={setContent} />
        </div>
      )
    }
  }

  const closeFilterPanel = (filterPanelIsOpen: boolean) => {
    if (filterPanelIsOpen) {
      setFilterPanelIsOpen()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <div className={styles.navigation__group}>
          <Button
            ariaLabel={ZOOM_IN}
            onClick={() => {
              closeFilterPanel(filterPanelIsOpen)
              setMapZoom(mapZoom + 1)
            }}
          >
            <IconPlus />
          </Button>
          <Button
            ariaLabel={ZOOM_OUT}
            onClick={() => {
              closeFilterPanel(filterPanelIsOpen)
              setMapZoom(mapZoom - 1)
            }}
            className={styles.navigation__padding__middleElement}
          >
            <IconMinus />
          </Button>
          <Button
            ariaLabel={CENTER_MAP}
            onClick={() => {
              closeFilterPanel(filterPanelIsOpen)
              setMapCenterOnCurrentLocation(currentPosition)
            }}
            className={styles.navigation__locationIcon}
          >
            {loading.isLoading && loading.id === "currentLocationIcon" ? (
              <Spinner />
            ) : (
              <IconMyLocation />
            )}
          </Button>
          <Button
            ariaLabel={DISPLAY_ABOUT_PAGE}
            onClick={() => navigate("/about/")}
            className={styles.navigation__padding__lastElement}
          >
            <IconInfo />
          </Button>
        </div>
      </div>

      <div
        className={`${styles.filters} ${
          filterPanelIsOpen ? styles.filtersVisible : styles.filtersHidden
        } ${content.type === CONTENT_VIEW.DETAIL ? styles.hidden : ""}`}
      >
        <Filters
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          setClosestLocation={setClosestLocation}
          loading={loading}
        />
      </div>

      {generateLargeScreenDetail(content.detail)}

      <Map
        locationsList={activeLocations}
        onChangeId={setContent}
        currentPosition={currentPosition}
        mapCenter={mapCenter}
        mapZoom={mapZoom}
        setMapZoom={setMapZoom}
        setMapCenter={setMapCenter}
        centerMapAction={centerMapAction}
        closestLocation={closestLocation}
        mapTileProvider={mapTileProvider}
      />
    </div>
  )
}

export { Content }
