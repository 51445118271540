import React, { ChangeEventHandler } from "react"
import styles from "./Checkbox.module.scss"
interface Props {
  checked: boolean
  id: string
  onChange: ChangeEventHandler<HTMLInputElement>
}

const Checkbox: React.FC<Props> = ({ checked = false, onChange, id }: Props) => {
  return (
    <form className={styles.checkbox}>
      <div>
        <input
          type="checkbox"
          value={checked.toString()}
          checked={checked}
          onChange={onChange}
          id={id}
          name={id}
        />
        <label htmlFor={id}>
          <span />
        </label>
      </div>
    </form>
  )
}

export { Checkbox }
