import React from "react"

const IconNavigate: React.FC = () => {
  return (
    <svg
      width="28"
      height="28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 201.979 201.979"
    >
      <path
        d="M196.937,43.129L145.643,0v22.442H39.288c-18.884,0-34.247,15.363-34.247,34.246v145.29h41.374V71.719
	c0-4.357,3.545-7.903,7.902-7.903h91.325v22.442L196.937,43.129z"
      />
    </svg>
  )
}

export { IconNavigate }
