import React, { ChangeEvent } from "react"

import { Button } from "../elements/Button"
import { Checkbox } from "../elements/Checkbox"
import { IconMyLocation } from "../icons/IconMyLocation"
import {
  LOCATION__TYPE__NURSINGROOM_FILTERLABEL_SHORT,
  LOCATION__TYPE__BATHROOM_FILTERLABEL_SHORT,
  LOCATION__TYPE__BATHROOM_FILTERLABEL_EXTRASHORT,
} from "../providers/texts"

import { FiltersType } from "../helpers/types"

import styles from "./Footer.module.scss"

interface Props {
  activeFilters: FiltersType
  setActiveFilters: (activeFilters: FiltersType) => void
  setFilterPanelIsOpen: () => void
}

const Footer: React.FC<Props> = ({
  activeFilters,
  setActiveFilters,
  setFilterPanelIsOpen,
}: Props) => {
  const handleSetActiveFilters = (
    param: keyof FiltersType,
    event?: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedState = JSON.parse(JSON.stringify(activeFilters))
    updatedState[param] = event ? event.target.checked : !updatedState[param]
    setActiveFilters(updatedState)
  }
  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <Checkbox
          id="footerButtonBabyroom"
          checked={activeFilters.isPublicNursingRoom}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleSetActiveFilters("isPublicNursingRoom", event)
          }
        />
        <button
          aria-label={LOCATION__TYPE__NURSINGROOM_FILTERLABEL_SHORT}
          className={styles.label}
          onClick={() => handleSetActiveFilters("isPublicNursingRoom")}
        >
          {LOCATION__TYPE__NURSINGROOM_FILTERLABEL_SHORT}
        </button>
        <Checkbox
          id="footerButtonBathroom"
          checked={activeFilters.isPublicBathroom}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleSetActiveFilters("isPublicBathroom", event)
          }
        />
        <button
          aria-label={LOCATION__TYPE__BATHROOM_FILTERLABEL_SHORT}
          className={styles.label}
          onClick={() => handleSetActiveFilters("isPublicBathroom")}
        >
          <span className={styles.labelSmallScreen}>
            {LOCATION__TYPE__BATHROOM_FILTERLABEL_EXTRASHORT}
          </span>
          <span className={styles.labelLargeScreen}>
            {LOCATION__TYPE__BATHROOM_FILTERLABEL_SHORT}
          </span>
        </button>
      </div>
      <Button
        className={styles.buttonMoreOptions}
        onClick={setFilterPanelIsOpen}
      >
        <>
          <IconMyLocation />
          <IconMyLocation />
          <IconMyLocation />
        </>
      </Button>
    </div>
  )
}

export { Footer }
