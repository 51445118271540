import React, { useState, useEffect } from "react"
import { useQueryParam, NumberParam } from "use-query-params"
import { Point } from "pigeon-maps"
import { navigate } from "gatsby"
import axios from "axios"

import Div100vh from "react-div-100vh"

import { toast } from "react-toastify"
import { Toast } from "./Toast"

import { Header } from "./Header"
import { Footer } from "./Footer"
import { Content } from "./Content"
import { Detail } from "./Detail"
import { CookiesConsentBar } from "./CookiesConsentBar"

import { GEOLOCATION_URL } from "../providers/urls"
import {
  GEOLOCATION__MAP_CANNOT_BE_CENTERED,
  GEOLOCATION__CANNOT_FIND_CLOSEST,
  GEOLOCATION__DISABLED,
  FIND_CLOSEST_LOCATION,
  NO_LOCATION_VISIBLE,
  GEOLOCATION__NOT_SUPPORTED,
  WITHIN_PRAGUE_BOUNDS__CURRENT_LOCATION,
  WITHIN_PRAGUE_BOUNDS,
} from "../providers/texts"
import {
  mapTilerTest,
  mapyCzTest,
  defaultLat,
  defaultLon,
} from "../providers/maptiles"

import * as locationSelectHelpers from "../helpers/locationSelectors"
import { VisibleContent, FiltersType, Location } from "../helpers/types"
import { handleError } from "../helpers/errorHandlers"
import {
  defaultContentView,
  CONTENT_VIEW,
  MAPTILER_VIEW,
} from "../helpers/enums"
import {
  handlePragueCentricWarning,
  userIsInPrague,
} from "../helpers/geolocationHandlers"

import styles from "./App.module.scss"

const App: React.FC = () => {
  // General App State
  const [content, setContent] = useState<VisibleContent>(defaultContentView)
  const [filterPanelIsOpen, setFilterPanelIsOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState(
    locationSelectHelpers.defaultActiveFilters
  )
  const [activeLocations, setActiveLocations] = useState(
    locationSelectHelpers.locationFilter(activeFilters)
  )

  // Map State
  const [mapTileProvider, setMapTileProvider] = useState<string | undefined>(
    undefined
  )
  const [mapCenter, setMapCenter] = useState<Point>([defaultLat, defaultLon])
  const [currentPosition, setCurrentPosition] = useState<Point | null>(null)
  const [mapZoom, setMapZoom] = useState(12)

  const [centerMapAction, setCenterMapAction] = useState(false)
  const [closestLocation, setClosestLocation] = useState<Location | null>(null)

  const [loading, setLoading] = useState({ isLoading: false, id: "" })

  const [activeLocationQueryParam, setActiveLocationQueryParam] = useQueryParam(
    "location",
    NumberParam
  )

  useEffect(() => {
    if (activeLocationQueryParam) {
      const activeLocation = locationSelectHelpers.locationSelector(
        activeLocationQueryParam
      )
      if (activeLocation) {
        setContent({
          type: CONTENT_VIEW.DETAIL,
          detail: activeLocation,
        })
      } else {
        navigate("/404")
      }
    }
  }, [activeLocationQueryParam])

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    const getMapTileProvider = () => {
      axios.get(mapyCzTest, { cancelToken: source.token }).then(
        res => {
          if (res.status === 200) {
            setMapTileProvider(MAPTILER_VIEW.MAPYCZ)
          }
        },
        error => {
          if (axios.isCancel(error)) {
            handleError(error, "App.tsx/getMapTileProvider")
          } else {
            axios.get(mapTilerTest, { cancelToken: source.token }).then(
              res => {
                if (res.status === 200) {
                  setMapTileProvider(MAPTILER_VIEW.MAPTILER)
                }
              },
              error =>
                handleError(error, "App.tsx/getMapTileProvider/secondError")
            )
          }
        }
      )
    }

    const warnIfLocationIsOutsidePrague = () => {
      axios.get(GEOLOCATION_URL, { cancelToken: source.token }).then(
        res => {
          const generalLocation = res.data
          const isInPrague = userIsInPrague([
            generalLocation.latitude,
            generalLocation.longitude,
          ])
          if (!isInPrague) {
            handlePragueCentricWarning()
          }
        },
        error => handleError(error, "App.tsx/getApproximateGeoLocation")
      )
    }

    getMapTileProvider()
    warnIfLocationIsOutsidePrague()

    return () => {
      source.cancel()
    }
  }, [])

  const handleSetContent = (updatedContent: VisibleContent) => {
    if (updatedContent.type === CONTENT_VIEW.MAP) {
      setContent({ type: CONTENT_VIEW.MAP, detail: null })
      setClosestLocation(null)
      setActiveLocationQueryParam(undefined)
      setCenterMapAction(false)
    } else if (
      updatedContent.type === CONTENT_VIEW.DETAIL &&
      updatedContent.detail
    ) {
      const activeLocation = updatedContent.detail
      setMapCenter(activeLocation.coordinates)
      setClosestLocation(activeLocation)
      setActiveLocationQueryParam(activeLocation.id)
      setContent({ type: CONTENT_VIEW.DETAIL, detail: activeLocation })
      toast.dismiss("closestLocation")
    }
  }

  const handleSetFilterPanelIsOpen = () => {
    setFilterPanelIsOpen(!filterPanelIsOpen)
  }

  const handleSetActiveFilters = (updatedActiveFilters: FiltersType) => {
    const newLocations = locationSelectHelpers.locationFilter(
      updatedActiveFilters
    )

    setActiveFilters(updatedActiveFilters)
    setActiveLocations(newLocations)
  }

  const handleSetCenterMapAction = () => {
    setCenterMapAction(true)
    setTimeout(() => setCenterMapAction(false), 5000)
  }

  const handleSetMapCenter = (center: Point | null) => {
    if (center) {
      setMapCenter(center)
    } else {
      toast(<Toast message={GEOLOCATION__MAP_CANNOT_BE_CENTERED} />)
    }
  }

  const handleSetMapCenterOnCurrentLocation = (
    currentPosition: Point | null
  ) => {
    if (currentPosition) {
      handleSetMapCenter(currentPosition)
      handleSetCenterMapAction()
    } else if (navigator.geolocation) {
      setLoading({ isLoading: true, id: "currentLocationIcon" })
      navigator.geolocation.getCurrentPosition(
        position => {
          setLoading({ isLoading: false, id: "" })
          const fetchedCurrentPosition: Point = [
            position.coords.latitude,
            position.coords.longitude,
          ]
          const isInPrague = userIsInPrague(fetchedCurrentPosition)
          if (isInPrague) {
            setCurrentPosition(fetchedCurrentPosition)
            setMapCenter(fetchedCurrentPosition)
            handleSetCenterMapAction()
          } else {
            toast(<Toast message={WITHIN_PRAGUE_BOUNDS__CURRENT_LOCATION} />, {
              autoClose: 8000,
            })
          }
        },
        error => {
          handleError(error, "App.tsx/navigator.geolocation.getCurrentPosition")
          setMapCenter([defaultLat, defaultLon])
          setLoading({ isLoading: false, id: "" })
          toast(
            <Toast
              message={GEOLOCATION__MAP_CANNOT_BE_CENTERED}
              type={error.code === 1 ? GEOLOCATION__DISABLED : ""}
            />,
            {
              autoClose: 8000,
            }
          )
        },
        {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0,
        }
      )
    } else {
      toast(<Toast message={GEOLOCATION__NOT_SUPPORTED} />)
      setMapCenter([defaultLat, defaultLon])
    }
  }

  const handleSetMapZoom = (zoom: number) => {
    setMapZoom(zoom)
  }

  const handleSetClosestLocation = () => {
    const positionOnMap = currentPosition ? currentPosition : null

    const findClosestLocation = (positionOnMap: Point) => {
      const locationsWithinRadius = locationSelectHelpers.getAllLocationsWithinRadius(
        activeLocations,
        positionOnMap,
        1
      )
      const closestLocation = locationSelectHelpers.getClosestLocation(
        activeLocations,
        positionOnMap
      )

      if (!closestLocation) {
        toast(<Toast message={NO_LOCATION_VISIBLE} />)
      } else {
        const distanceToClosestLocation = locationSelectHelpers
          .getDistanceFromLatLonInKm(
            positionOnMap[0],
            positionOnMap[1],
            closestLocation.coordinates[0],
            closestLocation.coordinates[1]
          )
          .toFixed(1)

        if (locationsWithinRadius.length) {
          setCurrentPosition([positionOnMap[0], positionOnMap[1]])
          toast(
            <Toast
              message={FIND_CLOSEST_LOCATION(
                distanceToClosestLocation,
                closestLocation.title,
                closestLocation.type,
                true
              )}
            />,
            { autoClose: 8000, toastId: "closestLocation" }
          )
          setClosestLocation(closestLocation)
          setTimeout(() => setClosestLocation(null), 5000)
        } else {
          const isInPrague = userIsInPrague([
            positionOnMap[0],
            positionOnMap[1],
          ])
          toast(
            <Toast
              message={
                isInPrague
                  ? FIND_CLOSEST_LOCATION(
                      distanceToClosestLocation,
                      closestLocation.title,
                      closestLocation.type,
                      false
                    )
                  : WITHIN_PRAGUE_BOUNDS +
                    FIND_CLOSEST_LOCATION(
                      distanceToClosestLocation,
                      closestLocation.title,
                      closestLocation.type,
                      false
                    )
              }
            />,
            { autoClose: 8000 }
          )
          setClosestLocation(closestLocation)
          setTimeout(() => setClosestLocation(null), 5000)
        }
        setMapCenter(closestLocation.coordinates)
      }
    }

    if (positionOnMap) {
      findClosestLocation(positionOnMap)
      handleSetFilterPanelIsOpen()
    } else {
      setLoading({ isLoading: true, id: "closestLocationButton" })
      navigator.geolocation.getCurrentPosition(
        position => {
          setLoading({ isLoading: false, id: "" })
          handleSetFilterPanelIsOpen()
          const fetchedCurrentPosition: Point = [
            position.coords.latitude,
            position.coords.longitude,
          ]
          findClosestLocation(fetchedCurrentPosition)
        },
        error => {
          handleError(
            error,
            "App.tsx/navigator.geolocation.getCurrentPosition/getClosest"
          )
          setLoading({ isLoading: false, id: "" })
          handleSetFilterPanelIsOpen()
          toast(
            <Toast
              message={GEOLOCATION__CANNOT_FIND_CLOSEST}
              type={error.code === 1 ? GEOLOCATION__DISABLED : ""}
            />,
            {
              autoClose: 8000,
              toastId: "closestLocation",
            }
          )
        },
        {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0,
        }
      )
    }
  }

  const resetState = () => {
    setContent(defaultContentView)
    setFilterPanelIsOpen(false)
    setActiveFilters(locationSelectHelpers.defaultActiveFilters)
    setActiveLocations(
      locationSelectHelpers.locationFilter(
        locationSelectHelpers.defaultActiveFilters
      )
    )
    setMapCenter(currentPosition ? currentPosition : [defaultLat, defaultLon])
    setCurrentPosition(currentPosition)
    setMapZoom(12)
    setActiveLocationQueryParam(undefined)
    handleSetCenterMapAction()
  }

  const generateDetailSmallScreen = (content: VisibleContent) =>
    content.type === CONTENT_VIEW.DETAIL &&
    content.detail && (
      <Div100vh className={styles.detailSmallScreen}>
        <Detail activeLocation={content.detail} goBack={handleSetContent} />
      </Div100vh>
    )

  return (
    <Div100vh>
      <div className={styles.container}>
        <Header resetState={resetState} />
        <div className={styles.content}>
          <Content
            content={content}
            filterPanelIsOpen={filterPanelIsOpen}
            activeFilters={activeFilters}
            activeLocations={activeLocations}
            mapTileProvider={mapTileProvider}
            mapCenter={mapCenter}
            currentPosition={currentPosition}
            mapZoom={mapZoom}
            centerMapAction={centerMapAction}
            closestLocation={closestLocation}
            loading={loading}
            setContent={handleSetContent}
            setFilterPanelIsOpen={handleSetFilterPanelIsOpen}
            setActiveFilters={handleSetActiveFilters}
            setMapCenter={handleSetMapCenter}
            setMapZoom={handleSetMapZoom}
            setClosestLocation={handleSetClosestLocation}
            setMapCenterOnCurrentLocation={handleSetMapCenterOnCurrentLocation}
          />
        </div>
        {generateDetailSmallScreen(content)}
        <Footer
          activeFilters={activeFilters}
          setActiveFilters={handleSetActiveFilters}
          setFilterPanelIsOpen={handleSetFilterPanelIsOpen}
        />
        <CookiesConsentBar />
      </div>
    </Div100vh>
  )
}

export { App }
