const MAPTILER_ACCESS_TOKEN = "mJ2Gks1SJ1wS3QB9wHnD"
const MAP_ID = "basic"

const mapTilerTest = `https://api.maptiler.com/maps/${MAP_ID}/256/0/0/0.png?key=${MAPTILER_ACCESS_TOKEN}`
const mapyCzTest = "https://mapserver.mapy.cz/turist-m/0-0-0-1"

// Prague Center
const defaultLat = 50.087961
const defaultLon = 14.419649

// Prague Bounds
const defaultLatN = 50.15743
const defaultLatS = 49.968492
const defaultLonE = 14.719306
const defaultLonW = 14.224845

const mapTiler = (
  x: number,
  y: number,
  z: number,
  dpr?: number | undefined
): string => {
  return `https://api.maptiler.com/maps/${MAP_ID}/256/${z}/${x}/${y}${
    dpr && dpr >= 2 ? "@2x" : ""
  }.png?key=${MAPTILER_ACCESS_TOKEN}`
}

const mapyCz = (
  x: number,
  y: number,
  z: number,
  dpr?: number | undefined
): string => {
  return `https://mapserver.mapy.cz/turist-m/${z}-${x}-${y}-${
    dpr && dpr >= 2 ? "@2x" : ""
  }`
}

export {
  mapTilerTest,
  mapyCzTest,
  mapTiler,
  mapyCz,
  defaultLat,
  defaultLon,
  defaultLatN,
  defaultLatS,
  defaultLonE,
  defaultLonW,
}
