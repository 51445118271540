import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import { MAPTILER_VIEW } from "../helpers/enums"
import {
  MAPTILER_MAPYCZ_URL,
  MAPTILER_OSM_URL,
  MAPTILER_MAPTILER_URL,
} from "../providers/urls"
import {
  COOKIES,
  ATTRIBUTIONS,
  MAPTILEPROVIDER__OSM,
  MAPTILEPROVIDER__MAPYCZ,
  MAPTILEPROVIDER__MAPTILER,
  MAPTILEPROVIDER__COPYRIGHT_NOTICE,
} from "../providers/texts"

interface Props {
  mapProvider: string
}

const MapAttribution: React.FC<Props> = ({ mapProvider }: Props) => {
  const linkInlineStyle = { color: `#178287` }
  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "_general/mapyczlogo.png" }) {
        childImageSharp {
          fixed(height: 15) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const generateMapAttribution = () => {
    if (mapProvider === MAPTILER_VIEW.MAPYCZ) {
      return (
        <a href={MAPTILER_MAPYCZ_URL} target="_blank" rel="noreferrer">
          <Img
            fixed={logo.file.childImageSharp.fixed}
            style={{
              marginBottom: `-3px`,
            }}
            alt={MAPTILEPROVIDER__MAPYCZ}
          />
        </a>
      )
    } else if (mapProvider === MAPTILER_VIEW.MAPTILER) {
      return (
        <a href={MAPTILER_MAPTILER_URL} target="_blank" rel="noreferrer">
          {MAPTILEPROVIDER__MAPTILER}
        </a>
      )
    } else {
      return (
        <a href={MAPTILER_OSM_URL} target="_blank" rel="noreferrer">
          {MAPTILEPROVIDER__OSM}
        </a>
      )
    }
  }

  const displayAttributionsLink = () => {
    return (
      <span>
        {" "}
        |{" "}
        <Link style={linkInlineStyle} to="/attributions/">
          {ATTRIBUTIONS}
        </Link>
      </span>
    )
  }

  const displayCookiesLink = () => {
    return (
      <span>
        {" "}
        |{" "}
        <Link style={linkInlineStyle} to="/cookies/">
          {COOKIES}
        </Link>
      </span>
    )
  }

  return (
    <span>
      {MAPTILEPROVIDER__COPYRIGHT_NOTICE}
      {generateMapAttribution()}
      {displayAttributionsLink()}
      {displayCookiesLink()}
    </span>
  )
}

export { MapAttribution }
